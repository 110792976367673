<template>
  <v-container
    fluid
    fill-height
    class="login"
    :style="{'background-image': `url(/bg/Wavey-Fingerprint.svg)`}"
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex shrink>
        <v-layout
          align-center
          column
          justify-center
        >
          <v-flex>
            <img
              style="cursor: default; user-select: none; pointer-events: none"
              src="dd_logo.png"
              width="576"
              class="mb-5"
              alt="Dericon Data & Documents"
            >
          </v-flex>
        </v-layout>
        <v-card
          class="elevation-12"
          light
        >
          <v-app-bar
            dark
            color="primary"
          >
            <v-toolbar-title>Login</v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                outlined
                prepend-icon="person"
                name="login"
                :label="$t('user.username')"
                type="text"
                @keyup.enter="$refs.pass.focus()"
              />
              <v-text-field
                id="password"
                ref="pass"
                v-model="password"
                outlined
                prepend-icon="lock"
                name="password"
                :label="$t('user.password')"
                type="password"
                @keyup.enter="login()"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <LocaleChanger />

            <v-spacer />
            <v-btn
              :loading="loading"
              color="light-blue darken-4"
              dark
              @click="login"
            >
              {{ $t('login.login') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import LocaleChanger from "@/components/common/LocaleChanger"
  import _get
    from "@/utlity/_get"

  export default {
    components: {LocaleChanger},
    data() {
      return {
        username: null,
        password: null,
        loading: false,
        bgs: [
          'Colorful-Stingrays',
          'Hollowed-Boxes',
          'Large-Triangles',
          'Subtle-Prism',
          'Wavey-Fingerprint',
          'Pattern-Randomized',
          'Polka-Dots',
          // 'Repeating-Chevrons',
        ],
      }
    },

    computed: {

      getRandomBackground() {
        return this.bgs[Math.floor(Math.random() * this.bgs.length)]
      },
    },

    methods: {
      ...mapActions(['fetchToken', 'setUser', 'fetchUser', 'fetchIndustryFields']),
      async login() {
        console.log('logging in')
        this.loading = true

        await this.fetchToken({
          username: this.username,
          password: this.password,
        })
        await this.fetchUser()
        this.loading = false
        // this.fetchIndustryFields()
        await this.$router.push('funds')
      },
    },

  }
</script>

<style>

.login {
  background-repeat: repeat;
  background-size: 300px;
}

</style>
